@media (max-width:1400px) {
    .headerInfo {
        padding: 10px 0 30px 0;
    }
    .tbldetails {
        margin: 30px 0 40px 0;
    }
    .logo .title {
        font-size: 80px;
        line-height: 90px;
    }
    .hdrTxt h1 {
        font-size: 26px;
    }
    .idDetails ul li {
        margin: 0 55px 0 0;
    }
    .idDetails ul li input {
        font-size: 36px;
        width: 140px;
        height: 67px;
    }
    .idDetails ul li span {
        right: -36px;
        font-size: 64px;
    }
    .idDetails ul li:nth-child(4) span {
        bottom: 7px;
        right: -40px;
    }
    .cidrTxt {
        padding: 0;
    }
    .idspc {
        padding: 40px 56px;
    }
}

@media (max-width:1300px) {}

@media (max-width:1200px) {
    .container {
        max-width: 100% !important;
        padding: 0 20px !important;
    }
    .logo .title {
        font-size: 70px;
        line-height: 70px;
        padding-bottom: 20px;
    }
    .hdrTxt h1 {
        font-size: 20px;
        line-height: 26px;
    }
    .idspc {
        padding: 40px 40px;
    }
    .idDetails ul li {
        margin: 0 40px 0 0;
    }
    .idDetails ul li input {
        font-size: 32px;
        width: 125px;
        height: 60px;
    }
    .idDetails ul li span {
        right: -27px;
        font-size: 56px;
    }
    .idDetails ul li:nth-child(4) span {
        bottom: 5px;
        right: -32px;
    }
}

@media (max-width:991px) {
    .headerInfo {
        padding: 0 0 20px 0;
    }
    .logo .title {
        font-size: 60px;
        line-height: 60px;
        padding-bottom: 6px;
        text-align: start;
    }
    .hdrTxt h1 {
        font-size: 20px;
        line-height: normal;
        padding-bottom: 20px
    }
    .label {
        padding: 5px;
        width: 45px;
        height: 22px;
    }
    .ball {
        top: 5px;
    }
    .ball::after {
        Width: 10px;
        Height: 10px;
        background-size: 10px !important;
        padding: 6px;
    }
    .cidrTxt {
        padding: 0;
    }
    .togleInfo {
        top: 20px;
    }
    .idspc {
        padding: 40px 20px;
    }
    .idDetails ul li {
        margin: 0 36px 0 0;
    }
    .idDetails ul li input {
        font-size: 28px;
        width: 96px;
        height: 55px;
    }
    .copyIcon {
        margin: 0 0 0 15px;
    }
    .idList {
        width: 50%;
        margin: 0 0 30px 0;
    }
    .idList:nth-child(3),
    .idList:last-child {
        margin: 0;
    }
    .idList p,
    .idList span {
        font-size: 18px;
    }
    .idDetails ul li:nth-child(4) span {
        right: -30px;
        bottom: 8px;
    }
    .idDetails ul li span {
        right: -25px;
        font-size: 50px;
        line-height: 42px;
    }
    .tblTitle h2 {
        font-size: 28px;
    }
    .tblTitle button {
        font-size: 18px;
        line-height: normal;
    }
    .tblTitle {
        padding: 20px 15px;
    }
    .tblnfo th,
    .tblnfo td {
        font-size: 16px;
    }
    .tblnfo td:first-child {
        padding-left: 40px !important;
    }
    .tblnfo th:first-child {
        padding-left: 25px;
    }
    .tbldetails {
        margin: 30px 0 40px 0;
    }
    .copyTxt {
        width: 45%;
        text-align: center;
    }
    .ftrSocial {
        width: 55%;
        text-align: center;
    }
    .ftrSocial span {
        display: block;
        margin: 0 0 20px 0;
    }
    .ftrDetails {
        padding: 20px 0;
    }
    .ftrSocial ul {
        padding: 0;
    }
    .error-page h1 {
        font-size: 80px;
    }
    .error-page h3 {
        font-size: 48px;
    }
}

@media (max-width:767px) {
    .togleInfo {
        top: 40px;
    }
    .headerInfo {
        padding: 30px 0 10px 0;
    }
    .logo .title {
        font-size: 45px;
        line-height: normal;
    }
    .hdrTxt h1 {
        font-size: 18px;
    }
    .idDetails ul li {
        margin: 0 28px 0 0;
    }
    .idDetails ul li input {
        font-size: 26px;
        width: 80px;
        height: 48px;
    }
    .idDetails ul li span {
        right: -20px;
        font-size: 44px;
        line-height: 36px;
    }
    .idDetails ul li:nth-child(4) span {
        right: -24px;
        bottom: 7px;
    }
    .idspc {
        padding: 40px 0 0 0;
    }
    .idInfo p,
    .idDetails {
        padding: 0 15px;
    }
    .listidInfo {
        margin: 30px 0 0 0;
        border-top: 0.5px solid #9993B3;
        padding: 30px 15px;
    }
    .addTablebtn {
        border: none;
    }
    .addTablebtn button {
        margin: 0 0 30px 0;
        padding: 10px 25px;
        font-size: 18px;
    }
    .tblnfo {
        padding: 0 5px;
        overflow-y: auto;
        max-height: 496px;
    }
    .tblnfo th {
        display: none;
    }
    .tblnfo td {
        display: flex;
        align-items: center;
        padding: 8px 15px !important;
        width: 100%;
        position: relative;
        padding-left: 40% !important;
    }
    .tblnfo td::before {
        content: 'Sr No:';
        position: absolute;
        top: 8px;
        left: 10px;
        width: 45%;
        padding-right: 10px;
        color: #9993B3;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.05em;
        text-align: left;
    }
    .tblnfo td:nth-child(2):before {
        content: 'Subnet Address';
    }
    .tblnfo td:nth-child(3):before {
        content: 'Host Address Range ';
    }
    .tblnfo td:last-child:before {
        content: 'Cost';
    }
    .tblnfo tr {
        border-bottom: 1px solid #413E53;
    }
    .tblnfo td:first-child {
        padding-left: 40% !important;
    }
    body.dark .tblnfo tr {
        border-bottom: 1px solid #413E53;
    }
    .tbldetails {
        margin: 25px 0;
    }
    .tblnfo table {
        margin: 0 0 20px 0;
    }
    .tbldetails tr.lstdt td {
        border: none;
    }
    .ftrSocial ul li {
        margin: 0 5px 0 0;
    }
    .nodata td::before {
        display: none;
    }
    .nodata td,
    .nodata td:first-child {
        display: block;
        padding: 10px 5px !important;
    }
    .error-page h1 {
        font-size: 70px;
    }
    .error-page h3 {
        font-size: 44px;
    }
}

@media (max-width:640px) {
    .hdrTxt h1 {
        padding-bottom: 0;
    }
    .togleInfo {
        top: 40px;
        right: 20px;
    }
    .idInfo p {
        font-size: 18px;
    }
    .idDetails ul li {
        margin: 0 20px 0 0;
    }
    .idDetails ul li input {
        font-size: 24px;
        width: 65px;
        height: 44px;
    }
    .idDetails ul li span {
        right: -15px;
        font-size: 40px;
        line-height: 32px;
    }
    .idDetails ul li:nth-child(4) span {
        right: -18px;
    }
    .tblnfo td,
    .tblnfo td:first-child {
        padding-left: 50% !important;
    }
    .tblTitle h2 {
        font-size: 24px;
    }
    .copyTxt a,
    .ftrSocial span {
        font-size: 18px;
    }
    .ftrSocial ul li {
        margin: 0 2px 0 0;
    }
    .ftrSocial ul li a {
        height: 28px;
        width: 28px;
    }
    .nodata td,
    .nodata td:first-child {
        display: block;
        padding: 10px 5px !important;
        font-size: 16px;
    }
    .error-page h1 {
        font-size: 60px;
    }
    .error-page h3 {
        font-size: 40px;
    }
    .error-page p {
        font-size: 20px;
    }
}

@media (max-width:575px) {
    .copyIcon {
        display: inline-block;
        border: 2px solid #1CB7F6;
        padding: 5px 15px;
        line-height: normal;
        margin: 30px 0 0 0;
        text-align: center;
        background: transparent;
        box-shadow: none;
        border-radius: 50px;
        width: auto;
        height: auto;
    }
    .copyimg {
        display: inline-block;
    }
    .copyIcon span {
        font-weight: 700;
        color: #19A3F4;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        font-size: 14px;
        margin: 0 5px 0 0;
    }
    .copyIcon:hover {
        background-color: #19A3F4;
    }
    .copyIcon:hover span {
        color: #fff;
    }
    .copyIcon:hover .copyBtnhvr {
        display: inline-block !important;
        width: 10px;
    }
    .copyIcon:hover .copybtndec {
        display: none !important;
    }
    .idInfo p {
        text-align: left;
    }
    .idList p,
    .idList span {
        text-align: center;
        font-size: 18px;
    }
}

@media (max-width:480px) {
    .container {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
    .togleInfo {
        right: 10px;
        top: 30px;
    }
    .label {
        width: 40px;
        height: 20px;
        padding: 5px;
    }
    .logo  .title {
        font-size: 36px;
    }
    .headerInfo {
        padding: 15px 0;
    }
    body.dark .ball::after {
        right: -6px;
    }
    .idDetails ul li {
        margin: 0 15px 0 0;
    }
    .idDetails ul li input {
        font-size: 16px;
        height: 28px;
        width: 40px;
        border-radius: 5px;
    }
    .idDetails ul li span {
        right: -11px;
        font-size: 30px;
        line-height: 22px;
    }
    .idDetails ul li:nth-child(4) span {
        right: -13px;
        bottom: 5px;
    }
    .idList p,
    .idList span {
        font-size: 14px;
        padding: 0;
    }
    .tblnfo td,
    .tblnfo td:first-child {
        padding-left: 45% !important;
        line-height: normal;
    }
    .tblTitle h2 {
        font-size: 18px;
    }
    .tblTitle button {
        padding: 8px 10px;
    }
    .tblnfo th,
    .tblnfo td,
    .tblTitle button {
        font-size: 14px;
    }
    .addTablebtn button {
        padding: 8px 15px;
        font-size: 16px;
    }
    .tblnfo td img {
        margin-left: 2px;
        width: 10px;
    }
    .copyTxt a,
    .ftrSocial span {
        font-size: 16px;
    }
    .tblnfo td,
    .tblnfo td::before {
        font-size: 13px;
    }
    .nodata td,
    .nodata td:first-child {
        padding: 10px 5px !important;
        font-size: 14px;
    }
    .ftrSocial ul li img {
        width: 15px;
    }
    .ftrSocial ul li:nth-child(2) img {
        width: 9px;
    }
    .ftrSocial ul li a {
        height: 25px;
        width: 25px;
    }
    .copyTxt {
        width: 44%;
    }
    .copyTxt img {
        width: 100px;
    }
    .ftrSocial {
        width: 56%;
    }
    .error-page h1 {
        font-size: 40px;
    }
    .error-page h3 {
        font-size: 30px;
    }
    .error-page p {
        font-size: 15px;
    }
}