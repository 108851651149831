@import url('//fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

body {
    font-family: 'Inter', sans-serif;
    transition: background 0.2s linear;
    background-color: #EAECF5 !important;
    font-size: 16px;
    font-weight: normal;
    color: #000;
    line-height: normal;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.headerInfo {
    padding: 30px 0 70px 0;
}

.logo .title {
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 98px;
    color: #19A8F5;
}

.hdrTxt h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    display: flex;
    align-items: center;
    color: #3A3A3A;
}

.cidrTxt {
    padding: 40px 0 0 0;
}

.cidrTxt a {
    display: inline-block;
    color: #9993B3;
    transition: 1s;
    text-decoration: none;
}

.cidrTxt a:hover {
    color: #19A8F5;
    transition: 1s;
}

.cidrTxt p {
    font-size: 20px;
    line-height: 159.49%;
    letter-spacing: 0.05em;
    color: #9993B3;
    text-align: start;
    font-weight: 500;
}

body.dark {
    background-color: #2A263E !important;
}

body.dark .hdrTxt h1 {
    color: #fff;
}

body.dark .cidrTxt p {
    color: #9993B3;
}

.checkbox {
    opacity: 0;
    position: absolute;
}

.togleInfo {
    top: 35px;
    right: 10px;
}

.label {
    width: 53px;
    height: 26px;
    background-color: #E9EAF0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    transform: scale(1.5);
    box-shadow: -6px -6px 12px rgba(255, 255, 255, 0.9), 6px 6px 12px rgba(189, 200, 223, 0.9);
    border-radius: 24px;
}

body.dark .label {
    background: #2F2B43;
    box-shadow: -1px -1px 5px rgb(255 255 255 / 25%), 1px 1px 5px rgb(0 0 0 / 25%);
}

.ball {
    position: absolute;
    top: 3px;
    left: 5px;
    border-radius: 50%;
    transition: transform 0.2s linear;
}

.ball::after {
    content: '';
    background: url(../img/lighmode.png) center no-repeat;
    Width: 15.72px;
    Height: 15.67px;
    background-size: 15px;
    position: absolute;
    padding: 10px;
    background-color: #E9EAF0;
    /* box-shadow: -10px -10px 20px rgba(255, 255, 255, 0.7), 10px 10px 20px rgba(189, 200, 223, 0.7); */
    border-radius: 43px;
}

body.dark .ball::after {
    background: url(../img/vector.png) center no-repeat;
}

.checkbox:checked+.label .ball {
    transform: translateX(24px);
}

.idInfo p {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #2F2B43;
}

body.dark .idInfo p {
    color: #EFECFF;
}

.idInfo {
    background: #EAECF5;
    box-shadow: -6px -6px 12px rgba(255, 255, 255, 0.7), 6px 6px 12px rgba(189, 200, 223, 0.7);
    border-radius: 24px;
}

body.dark .idInfo {
    background: #2F2B43;
    box-shadow: -18px -18px 36px rgba(255, 255, 255, 0.1), 18px 18px 36px rgba(0, 0, 0, 0.1), inset 0px 4px 42px rgba(0, 0, 0, 0.02);
    border-radius: 40px;
}

.idDetails ul li {
    display: inline-block;
    vertical-align: top;
    margin: 0 60px 0 0;
    position: relative;
}

.idDetails ul li:last-child {
    margin: 0;
}

.idDetails ul li input {
    border: none;
    line-height: normal;
    width: 160px;
    height: 75px;
    background-color: #F650C8;
    font-weight: 700;
    font-size: 40px;
    line-height: 189.99%;
    color: #fff;
    border-radius: 12px;
    text-align: center;
    outline: none;
}

.idDetails ul li input.color-2 {
    background: #28B95E;
}

.idDetails ul li input.color-3 {
    background: #F0783C;
}

.idDetails ul li input.color-4 {
    background: #F04B69;
}

.idDetails ul li input.color-5 {
    background: #FFBEBE;
    color: #2F2B43;
}

.idDetails ul li span {
    font-size: 70px;
    line-height: 55px;
    position: absolute;
    bottom: 0px;
    right: -38px;
}

.idDetails ul li:nth-child(4) span {
    bottom: 15px;
    right: -44px;
}

.idDetails ul li input::focus {
    border: none;
    outline: none;
}

body.dark .idDetails ul li span {
    color: #fff;
}

.idspc {
    padding: 40px 75px;
}

.copyIcon {
    margin: 0 0 0 40px;
    background: #19A8F5;
    box-shadow: inset -6px -6px 12px rgba(255, 255, 255, 0.25), inset 6px 6px 12px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    width: 44px;
    height: 44px;
    line-height: 40px;
    cursor: pointer;
}

.copyimg img {
    box-shadow: 0 0 1px rgb(0 0 0 / 0%);
}

.copyIcon:active {
    transform: translateY(2px);
}

.listidInfo {
    margin: 50px 0 0 0;
}

.idList {
    display: inline-block;
    vertical-align: top;
    width: 25%;
}

.idList p {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin: 0;
    color: #2F2B43;
}

body.dark .idList p {
    color: #FCE4D8;
}

.idList span {
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #9993B3;
}

body.dark .idList span {
    color: #9993B3;
}

.addTablebtn button {
    display: inline-block;
    text-decoration: none;
    padding: 13px 35px;
    margin: 30px 0;
    background-color: #19A8F5;
    box-shadow: -6px -6px 12px rgba(255, 255, 255, 0.7), 6px 6px 12px rgba(189, 200, 223, 0.7);
    letter-spacing: 0.05em;
    color: #FFFFFF;
    border: none;
    text-transform: uppercase;
    border-radius: 12px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    position: relative;
    background-image: radial-gradient(49.55% 440.73% at 60.36% 35.42%, rgba(84, 27, 157, 0.5) 22.92%, rgba(10, 60, 86, 0) 100%);
}

.addTablebtn button:hover {
    color: #fff;
    background-image: none;
}

.addTablebtn {
    border-top: 0.5px solid #9993B3;
}

body.dark .addTablebtn button {
    background-color: #19A8F5;
    box-shadow: -18px -18px 36px rgba(255, 255, 255, 0.1), 18px 18px 36px rgba(0, 0, 0, 0.1);
}

.tbldetails {
    margin: 70px 0 40px 0;
    float: left;
    width: 100%;
    background: #EAECF5;
    box-shadow: -6px -6px 12px rgba(255, 255, 255, 0.7), 6px 6px 12px rgba(189, 200, 223, 0.7);
    border-radius: 24px;
}

body.dark .tbldetails {
    background: #2F2B43;
    box-shadow: -18px -18px 36px rgba(255, 255, 255, 0.1), 18px 18px 36px rgba(0, 0, 0, 0.1), inset 0px 4px 42px rgba(0, 0, 0, 0.02);
    border-radius: 40px;
}

.tblTitle {
    padding: 20px 40px;
}

.tblTitle h2 {
    font-weight: 700;
    color: #2F2B43;
    font-size: 32px;
    margin: 0;
}

body.dark .tblTitle h2 {
    color: #EFECFF;
}

.tblTitle button {
    letter-spacing: 0.05em;
    border: 1px solid #F04B69;
    color: #F04B69;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 12px 16px;
    border-radius: 10px;
    background-color: transparent;
}

.tblTitle button:hover {
    color: #19A8F5;
    border-color: #19A8F5;
}

.tblnfo th {
    background-color: #19A8F5 !important;
    border-bottom: 1px solid #19A8F5;
    font-weight: 500;
    font-size: 18px;
    color: #E9EAF0;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

body.dark .tblnfo th {
    background-color: #29263B !important;
    border-bottom: 1px solid #29263B;
}

.tblnfo tr {
    border-bottom: 1px solid #413E53;
}

body.dark .tblnfo tr {
    border-bottom: 1px solid #413E53;
}

.tblnfo tr:last-child,
body.dark .tblnfo tr:last-child {
    border: none;
}

.tblnfo td {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #2F2B43;
    letter-spacing: 0.05em;
    border: none;
    padding: 15px 0.5rem !important;
}

body.dark .tblnfo td {
    color: #9993B3;
}

.tblnfo td:first-child {
    padding-left: 60px !important;
}

.tblnfo th:first-child {
    padding-left: 40px;
}

.tblnfo tr:hover {
    background-color: #E9EAF0 !important;
    box-shadow: -6px -6px 12px rgba(255, 255, 255, 0.7), 6px 6px 12px rgba(189, 200, 223, 0.7);
    border-radius: 20px;
}

body.dark .tblnfo tr:hover {
    background-color: #2F2B43 !important;
    box-shadow: -6px -6px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25);
}

.darkcopy {
    display: none;
}

body.dark .darkcopy {
    display: inline-block;
}

body.dark .lightcopy {
    display: none;
}

.tblnfo table {
    margin: 0;
}

.tblnfo td img {
    margin-left: 10px;
    cursor: pointer;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.tblnfo td img:active {
    transform: translateY(2px);
}

.table-data{
    padding: 0px !important;
}

.nodata td,
.nodata td:first-child {
    font-size: 22px;
    color: #2F2B43;
    font-weight: 500;
    text-align: center;
    padding: 30px 0 !important;
}

.ftrDetails {
    background: #EAECF5;
    box-shadow: -6px -6px 12px rgba(255, 255, 255, 0.7), 6px 6px 12px rgba(189, 200, 223, 0.7);
    padding: 36px 0;
}

body.dark .ftrDetails {
    background: #282439;
    box-shadow: -6px -6px 12px rgba(255, 255, 255, 0.1), 6px 6px 12px rgba(0, 0, 0, 0.1);
}

.copyTxt a {
    display: inline-block;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: #9993B3;
    text-decoration: none;
}

.copyTxt a:hover {
    color: #1490FE;
}

.ftrSocial span {
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: #9993B3;
    display: inline-block;
    vertical-align: middle;
}

.ftrSocial ul {
    display: inline-block;
    vertical-align: middle;
    padding: 0 0 0 35px;
}

.ftrSocial ul li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px 0 0;
}

.ftrSocial ul li:last-child {
    margin: 0;
}

.ftrSocial ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    background: #E9EAF0;
    box-shadow: -6px -6px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25);
    border-radius: 43px;
    width: 35px;
    height: 35px;
    text-align: center;
}

.ftrSocial ul li a:hover {
    background: #19A8F5;
}

.ftrSocial ul li:hover .offhover {
    display: inline-block;
}

.ftrSocial ul li .offhover {
    display: none;
}

.ftrSocial ul li:hover .onhover {
    display: none;
}

.ftrSocial ul li .onhover {
    display: inline-block;
}

@-webkit-keyframes hvr-wobble-skew {
    16.65% {
        -webkit-transform: skew(-12deg);
        transform: skew(-12deg);
    }
    33.3% {
        -webkit-transform: skew(10deg);
        transform: skew(10deg);
    }
    49.95% {
        -webkit-transform: skew(-6deg);
        transform: skew(-6deg);
    }
    66.6% {
        -webkit-transform: skew(4deg);
        transform: skew(4deg);
    }
    83.25% {
        -webkit-transform: skew(-2deg);
        transform: skew(-2deg);
    }
    100% {
        -webkit-transform: skew(0);
        transform: skew(0);
    }
}

@keyframes hvr-wobble-skew {
    16.65% {
        -webkit-transform: skew(-12deg);
        transform: skew(-12deg);
    }
    33.3% {
        -webkit-transform: skew(10deg);
        transform: skew(10deg);
    }
    49.95% {
        -webkit-transform: skew(-6deg);
        transform: skew(-6deg);
    }
    66.6% {
        -webkit-transform: skew(4deg);
        transform: skew(4deg);
    }
    83.25% {
        -webkit-transform: skew(-2deg);
        transform: skew(-2deg);
    }
    100% {
        -webkit-transform: skew(0);
        transform: skew(0);
    }
}

.error-page {
    text-align: center;
    margin: 40px 0px
}

.error-page h1 {
    font-size: 90px;
    color: #19A8F5;
    margin: 0 0 15px 0;
    padding: 0;
    line-height: 1;
}

.error-page h3 {
    font-size: 54px;
    color: #3A3A3A;
    margin: 0;
    padding: 0;
    line-height: 1;
}

.error_details .cidrTxt p {
    display: none;
}

body.dark .error-page h3 {
    color: #fff;
}

.error-page p {
    margin-top: 36px;
    font-size: 21px;
    color: #9993B3;
}

.error-page button {
    border: none;
    outline: none;
    font-size: 21px;
    font-weight: bold;
    border-radius: 21px;
    padding: 0;
    margin: 18px 0 9px 0;
    background: #19A8F5;
    box-shadow: inset -6px -6px 12px hsl(0deg 0% 100% / 25%), inset 6px 6px 12px rgb(0 0 0 / 25%);
    line-height: 40px;
    cursor: pointer;
    text-transform: uppercase;
}

.error-page .back-btn {
    text-decoration: none;
    color: #fff;
    display: block;
    padding: 6px 27px;
}

.error-page button:hover {
    box-shadow: inset -6px -6px 12px hsl(0deg 0% 100% / 10%), inset 6px 6px 12px rgb(0 0 0 / 40%);
}

.error-page button:active {
    transform: translateY(2px);
}

.error-footer {
    width: 100%;
    position: fixed;
    bottom: 0;
}